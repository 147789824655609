import dynamic from 'next/dynamic'

const AdminContainer = dynamic(() => import('layouts/containers/Admin'))

const adminRoutes = [
  {
    path: '/admin/login',
    exact: true,
  },
  {
    path: '/admin/dashboard',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/role-manajemen',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-akun-admin',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-slta',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-jurusan-slta',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-lokasi-ujian',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-daerah-pembibitan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-sekolah-tujuan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-program-studi',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/profile-prodi',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-alur-pendaftaran',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-alur-tes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-perjadwalan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-akun-peserta',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/data-pendaftar',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/peserta-yang-dihapus',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-kesehatan-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-tilok',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-tilok-status',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-daftar-peserta',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-psikotes-wawancara',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-formulir',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-wawancara',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-tpa',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konfirmasi-pembayaran-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/verif-formulir-non-polbit',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/verif-formulir-non-polbit/[pesertaId]',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/hasil-tes-bakat-terbang',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/evaluasi-tes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/manajemen-step-peserta',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/history-pembayaran-fomulir',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/history-pembayaran-wawancara',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/history-pembayaran-tpa',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/history-pembayaran-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/history-pembayaran-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/history-pembayaran-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/history-formulir-peserta',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-preview',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/e-kandidat',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/email-blast',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/pengaturan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-alur-jadwal',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-panduan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-template-berkas',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-pengumuman',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-pengumuman/buat',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-pengumuman/[pengumumanId]',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-faq',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-faq/buat',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/konten-faq/[faqId]',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/gallery',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/riwayat',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/final-hasil-seleksi',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/riwayat-wawancara',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/riwayat-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/riwayat-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/riwayat-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
]

export default adminRoutes
