import dynamic from 'next/dynamic'

const UserContainer = dynamic(() => import('layouts/containers/User'))

const userRoutes = [
  {
    name: 'HOME',
    path: '/',
    layout: UserContainer,
    exact: true,
  },
  {
    name: 'PROFIL SEKOLAH',
    path: '/profil-sekolah',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/formulir-lokasi-ujian',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/upload-berkas',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/formulir-calon-taruna',
    layout: UserContainer,
    exact: true,
  },
  {
    name: 'INFO PANDUAN',
    path: '/panduan',
    layout: UserContainer,
    exact: true,
  },
  {
    name: 'TEMPLATE BERKAS',
    path: '/template',
    layout: UserContainer,
    exact: true,
  },
  {
    name: 'PENGUMUMAN',
    path: '/pengumuman',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/pengumuman/[pengumumanId]',
    layout: UserContainer,
    exact: true,
  },
  {
    name: 'HELPDESK',
    path: '/helpdesk',
    layout: UserContainer,
    exact: true,
  },
  {
    name: 'GALERI',
    path: '/galeri',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/registrasi',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/verifikasi/[unixId]',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/reset-password',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/reset-password/[linkId]',
    layout: UserContainer,
    exact: true,
  },
  {
    path: '/resend-verification',
    layout: UserContainer,
    exact: true,
  },
]

export default userRoutes
