import dynamic from 'next/dynamic'

const AdminContainer = dynamic(() => import('layouts/containers/Admin'))

const adminRoutes = [
  /**
   * Import hasil tes
   */
  {
    path: '/admin/import-hasil-tes-wawancara1',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-hasil-tes-wawancara2',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-hasil-tes-potensi-akademik',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-hasil-tes-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-hasil-tes-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-hasil-tes-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-hasil-performance-tes',
    layout: AdminContainer,
    exact: true,
  },

  /**
   * Daftar hasil tes
   */
  {
    path: '/admin/daftar-hasil-tes-wawancara1',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-hasil-tes-wawancara2',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-hasil-tes-potensi-akademik',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-hasil-tes-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-hasil-tes-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-hasil-tes-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-hasil-performance-tes',
    layout: AdminContainer,
    exact: true,
  },

  /**
   * Import peserta lulus tes
   */
  {
    path: '/admin/import-peserta-lulus-tes-wawancara1',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-peserta-lulus-tes-wawancara2',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-peserta-lulus-tes-potensi-akademik',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-peserta-lulus-tes-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-peserta-lulus-tes-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-peserta-lulus-tes-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/import-peserta-lulus-performance-tes',
    layout: AdminContainer,
    exact: true,
  },

  /**
   * Daftar lulus tes
   */
  {
    path: '/admin/daftar-peserta-lulus-wawancara',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-gugur-wawancara',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-gugur-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-gugur-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-gugur-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-gugur-performance-tes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-lulus-tes-wawancara2',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-lulus-tes-potensi-akademik',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-lulus-tes-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-lulus-tes-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-lulus-tes-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/daftar-peserta-lulus-performance-tes',
    layout: AdminContainer,
    exact: true,
  },

  /**
   * Rekapitulasi Tes
   */
  {
    path: '/admin/rekapitulasi-hasil-tes-wawancara1',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-hasil-tes-wawancara2',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-tes-potensi-akademik',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-hasil-tes-kesehatan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-hasil-tes-psikotes',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-hasil-tes-kesemaptaan',
    layout: AdminContainer,
    exact: true,
  },
  {
    path: '/admin/rekapitulasi-hasil-performance-tes',
    layout: AdminContainer,
    exact: true,
  },
]

export default adminRoutes
