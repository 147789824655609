import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import Loading from 'components/Loading/Loading'
import getSiteLayout from 'layouts/core/DefaultLayout'
import dayjs from 'dayjs'
import moment from 'moment'
import 'moment/locale/id'
import momentTZ from 'moment-timezone'
import 'dayjs/locale/id'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'styles/global.scss'
import 'styles/global.css'

moment.locale('id')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.locale('id')

momentTZ.tz.setDefault('Asia/Jakarta')
dayjs.tz.setDefault('Asia/Jakarta')

const title = 'SIPENCATAR'
const description = 'Kementerian Perhubungan Republik Indonesia'
const metaURL = 'https://sipencatar.dephub.go.id/'
const metaImage = '/images/logo.png'

export default function MyApp(props: AppProps) {
  const [firstMount, setFirstMount] = useState(false)
  const cacheURL = useRef([])
  const refLoading = useRef<HTMLElement>(null)
  const SiteLayout = getSiteLayout(props)

  function refreshStyle(url: string) {
    if (process.env.NODE_ENV === 'production') {
      const els = document.querySelectorAll('link[rel="preload"][as="style"]')

      els.forEach((el) => {
        el.setAttribute('rel', 'stylesheet')
      })
    } else {
      if (cacheURL.current.includes(url)) return

      const timestamp = new Date().valueOf()
      const els: NodeListOf<HTMLLinkElement> = document.querySelectorAll(
        'link[href*="/_next/static/css/styles.chunk.css"]',
      )

      for (let i = 0; i < els.length; i += 1) {
        if (els[i].rel === 'stylesheet') {
          els[i].href = `/_next/static/css/styles.chunk.css?v=${timestamp}`
          cacheURL.current.push(url)
          break
        }
      }
    }
  }

  const listenLoading = useCallback((isListen: boolean) => {
    const start = () => {
      refLoading.current.style.visibility = 'visible'
    }

    const done = () => {
      refLoading.current.style.visibility = 'hidden'
    }

    const event = isListen ? 'on' : 'off'
    Router.events[event]('routeChangeStart', start)
    Router.events[event]('routeChangeComplete', done)
    Router.events[event]('routeChangeError', done)
  }, [])

  useEffect(() => {
    Router.events.on('routeChangeComplete', refreshStyle)
    setFirstMount(true)

    return () => {
      Router.events.off('routeChangeComplete', refreshStyle)
      listenLoading(false)
    }
  }, [])

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='title' content={title} />
        <link rel='shortcut icon' href='/images/logo.png' />
        <meta name='description' content={description} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={metaURL} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={metaImage} />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content={metaURL} />
        <meta property='twitter:title' content={title} />
        <meta property='twitter:description' content={description} />
        <meta property='twitter:image' content={metaImage} />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0'
        />
      </Head>

      {firstMount && (
        <Loading
          ref={(ref) => {
            if (ref) {
              refLoading.current = ref
              refLoading.current.style.visibility = 'hidden'
              listenLoading(true)
            }
          }}
          style={{
            display: 'none',
          }}
        />
      )}

      {SiteLayout}
    </>
  )
}
