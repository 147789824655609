import React from 'react'
import { Spin } from 'antd'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import cx from 'classnames'
import cssLoading from './Loading.module.scss'

const Loading = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function FowardedLoading(props, ref) {
  return (
    <div className={cx(cssLoading.loading)} ref={ref}>
      <Spin
        size='large'
        indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
      />
      <p>Loading...</p>
    </div>
  )
})

export default Loading
